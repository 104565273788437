.react-responsive-modal-overlay {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-family: "GunarBold";

  .main-modal {
    background: url($cdn + "Popup.png") center/100% 100% no-repeat;
    width: 90%;
    max-width: 42rem;
    height: 70%;
    max-height: 24rem;
    min-height: 15rem;
    padding: 5% 2% 2% 2%;
    display: grid;
    place-items: center;

    &__header {
      display: flex;
      width: 91%;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 1%;
      left: 8%;
      text-transform: uppercase;

      .btn-close {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      }
    }

    &__content {
      text-align: center;
      height: 35%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .confirm-modal {
        display: grid;
        place-items: center;
        margin-top: 3vh;

        .btn-orange {
          padding: 2rem;
          font-family: "GunarBold";
        }
      }
    }

    .react-responsive-modal-closeButton {
      svg {
        display: none;
      }
    }
  }
}
