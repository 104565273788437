.registration-form {
  font-family: "GunarBold";
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .member-data {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__sub-title {
    background: $darkGrey;
    color: $white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-trash {
      height: 1rem;
    }
  }

  .form-block {
    background: $lightGrey;
    padding: 0.5rem;
    width: 32%;
    display: flex;
    align-items: center;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .err-msg {
      color: $red;
      font-size: 0.8rem;
    }

    &__input {
      display: flex;
      justify-content: space-between;
      gap: 2%;

      label {
        flex: 2;
        display: inline;

        .required {
          color: $red;
          font-size: 1.2rem;
        }
      }

      input,
      select {
        flex: 3;
        border: none;
        font-family: "GunarBold";
      }
    }
  }

  .action-btn {
    margin: 2rem 0 1rem 0;

    button {
      width: 8rem;
      height: 2rem;
      font-family: "GunarBold";
      font-size: 0.7rem;
    }
  }

  .confirm-text {
    font-family: "Gunar";
    font-size: 0.8rem;
    color: $grey;
    text-align: center;
  }
}
