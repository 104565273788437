.event-info {
  text-align: center;
  line-height: 1.5rem;

  &__header {
    background: $darkGrey;
    color: $grey;
    font-family: "GunarBold";
    padding: 0.4rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
  }

  &__content {
    display: flex;
    justify-content: center;
    text-align: justify;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    padding: 0 8%;

    ul {
      margin-left: 1.5rem;
      text-align: left;
    }
  }

  &__register-info {
    padding: 4%;
    color: $red;
    font-family: "GunarBold";

    .registered {
      color: $green;
    }
  }
}
