.team-info {
  padding: 1.5rem 0 1.5rem 0;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  font-size: 0.8rem;

  .data-row {
    background: $lightGrey;
    font-family: "GunarBold";
    padding: 0.5rem;
    width: 32%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .data-row.hide {
    opacity: 0;
  }

  &__team-data,
  &__member-data {
    display: flex;
    justify-content: space-between;
  }
}

.action-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;

  button {
    width: 8rem;
    height: 2rem;
    font-family: "GunarBold";
  }
}
