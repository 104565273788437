$cdn: "https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/regis/";

@import "reset", "fonts", "utils", "partials/layout", "partials/footer",
  "partials/modals", "partials/eventinfo", "pages/teaminfo", "pages/form";

html {
  scroll-behavior: smooth;
  background: $black;
  color: $white;
  font-family: "Gunar";

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
    color: #000;

    &:hover {
      filter: brightness(0.8);
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    list-style-position: inside;
  }
}
