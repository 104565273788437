$black: #000000;
$grey: #a3a3a3;
$lightGrey: #d4d4d4;
$darkGrey: #282828;
$white: #f3f3f3;
$green: #94c488;
$red: #ee8282;

.btn-red {
  background: url($cdn + "Btn_red.png") 100%/100% no-repeat;
}

.btn-orange {
  background: url($cdn + "Btn_orange.png") 100%/100% no-repeat;
}

.btn-grey {
  background: url($cdn + "Btn_grey.png") 100%/100% no-repeat;
}

.btn-close {
  background: url($cdn + "Btn_Close.png") 100%/100% no-repeat;
}

.btn-trash {
  background: url($cdn + "Delete.png") 100%/100% no-repeat;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
