@font-face {
  font-family: "Gunar";
  src: url($cdn + "fonts/Gunar.ttf");
}

@font-face {
  font-family: "GunarBold";
  src: url($cdn + "fonts/Gunar Bold.ttf");
}
@font-face {
  font-family: "GunarMedium";
  src: url($cdn + "fonts/Gunar Medium.ttf");
}

@font-face {
  font-family: "Gunarlight";
  src: url($cdn + "fonts/Gunar Light.ttf");
}
