footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2% 0;
  background: $black;
  width: 100vw;

  .icons {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20%;
      margin: 0 0.5rem;
      max-width: 10rem;
    }
  }

  .copyright {
    width: 80%;
    max-width: 30rem;
    font-family: "GunarLight";
    font-size: 0.6rem;
    line-height: 0.8rem;
  }
}
